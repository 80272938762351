input[type="radio"].radio-as-button {
    display: none;
  }
  
  input[type="radio"].radio-as-button:checked + label {
    background-color: $pubdeepSkyBlue;
    color: $pubWhite;
  }
  
  input[type="radio"].radio-as-button + label {
    padding: 20px 30px;
    justify-content: center;
    background-color: $pubPaleGrey;
    color: $pubdeepSkyBlue;
    border: 1px solid $pubdeepSkyBlue;
    display: flex;
    align-items: center;
    font-size: 16px;
    @media screen and (min-width: 600){
      padding: 14px 50px;
    }
  }
  
  input[type="radio"].radio-as-button:checked + label.btn:hover {
    background-color: inherit;
    background-position: 0 0;
    transition: none;
  }
  
  input[type="radio"].radio-as-button-left + label {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  input[type="radio"].radio-as-button-right + label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }