.chatContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chatMessage {
    background-color: #0f0f0f;
    padding: 10px 10px;
    flex: 1 1;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  .chatInput {
    box-shadow: 0 -1px 1px 0 rgba(0,0,0,.34);
    position: relative;
    z-index: 1;
    padding: 10px 20px 5px;
    background: #1b1b1b!important;
    font-family: Helvetica,Arial,sans-serif;
  }
  
  .inputArea {
    height: 41px;
    border: 1px solid rgba(0,0,0,.4);
    border-radius: 2px;
    color: #fff;
    font-size: 13px;
    width: 100%;
    outline: none;
    min-height: 42px;
    transition: height .1s ease-in;
    resize: none;
    background: #101010!important;
    margin: 0;
    overflow: auto;
    font-family: Helvetica Neue,Helvetica,Arial,sans-serif;
    box-sizing: border-box;
    padding: 12px;
  }
  
  .emojiselect {
    right: 56px;
    top: calc(100% - 40px);
    position: absolute;
    cursor: pointer;
    width: 22px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-size: 44px 44px;
    fill: #fff;
  }
  
  .fileUploadDiv {
    right: 79px;
    top: calc(100% - 39px);
    position: absolute;
    cursor: pointer;
    width: 22px;
    height: 22px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-size: 44px 44px;
    fill: #fff
    /* fill: rgba(0,0,0,.6); */
  }
  
  .fileInput {
    width: 0;
    height: 0;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .divTextarea {
    font-size: 14px;
    height: auto;
    flex: 1 1;
  }
  
  .textAreaWrapper {
    display: flex;
  }

  .emojiPicker {
    position: absolute;
    bottom: 100%;
    right: 0;
  }
  
  .send-button {
      border: 0;
      background: 0;
      outline: 0;
  }

  .messageInputDiv {
    color: #fff
  }

  .inputImage {
    margin: 3px;
    max-width: 270px;
    object-fit: contain;
  }

  .inputNameLabel {
    color: gray;
    font-size: 0.8rem;
    font-weight: bold;
    margin-right: 1em;
  }

  .inputMessage {
    color: white;
    font-size: 0.9rem;
    line-height: 2em;
  }

  .imageDiv {
    display: flex;
  align-items: top;
  margin-bottom: 1em;
  }

  .dateSeparator {
    display: flex;
    margin: 40px;
    align-items: center;
  }

  .dateSeparatorLine {
    background-color: hsla(0,0%,100%,.1);
    margin-right: 20px;
    flex: 1 1;
    height: 1px;
    border: none;
  }

  .dateSeparatorDate {
    color: hsla(0,0%,100%,.7);
  }