.styled-checkbox {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
  
    & + label {
      position: relative;
      cursor: pointer;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      border: 1px solid $pubdeepSkyBlue;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: $pubPaleGrey;
    }

    &:checked + label{
      background-color: $pubdeepSkyBlue;
    }

    &:checked + label > div > div:first-child{
      color: $pubWhite;
    }

    &:checked + label > div > div:last-child{
      color: $pubVeryLightPink;
    }
  
    & + label > label{
      position: relative;
      z-index: 1;
    }
  
    // Box.
    & + label > label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background:$pubWhite;
      border: 1px solid $pubdeepSkyBlue;
    }
  
    // Box checked
    &:checked + label > label:before {
      background: $pubWhite;
    }
  
    // Checkmark. Could be replaced with an image
    &:checked + label > label:after {
      content: '';
      position: absolute;
      left: 10px;
      top: 15px;
      background: $pubdeepSkyBlue;
      width: 2px;
      height: 2px;
      box-shadow: 
        2px 0 0 $pubdeepSkyBlue,
        4px 0 0 $pubdeepSkyBlue,
        4px -2px 0 $pubdeepSkyBlue,
        4px -4px 0 $pubdeepSkyBlue,
        4px -6px 0 $pubdeepSkyBlue,
        4px -8px 0 $pubdeepSkyBlue;
      transform: rotate(45deg);
    }
}