//@import 'common/styles/colors.scss';
// @import url('https://fonts.googleapis.com/css?family=Muli&display=swap');

.App {
  text-align: left;
  // font-family: 'Muli', sans-serif;
}

.App-logo {
  height: 40vmin;
}


.App-link {
  color: #09d3ac;
}


.RichEditor-root {
  
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  padding:5px;
  //background-color: lightgrey;
  margin-bottom: 64px;
}

.RichEditor-editor {
  // padding:10px;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  //margin: 0 -15px -15px;
  // padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color:grey;
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}
.public-DraftStyleDefault-orderedListItem {
  padding-left: -31px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
  border:"#5890ff 1.5px solid",
}
@import 'assets/styles/_colors.scss';
@import 'assets/styles/styled-checkbox.scss';
@import 'assets/styles/radio-as-label.scss';

/** css for guillotine 

 /* Grid default theme */
 body.guillotine-dragging,
 body.guillotine-dragging * {
   cursor: move !important;
   cursor: -webkit-grabbing !important;
   cursor: -moz-grabbing !important;
   cursor: grabbing !important;
   cursor: grabbing, move;
   /* IE hack */
 }

 .guillotine-window {
   display: block;
   position: relative;
   overflow: hidden;
   cursor: move;
   cursor: -webkit-grab;
   cursor: -moz-grab;
   cursor: grab;
   cursor: grab, move;
   /* IE hack */
 }

 .guillotine-canvas {
   position: absolute;
   top: 0;
   left: 0;
   text-align: center;
   margin: 0 !important;
   padding: 0 !important;
   border: none !important;
 }

 .guillotine-canvas>* {
   position: absolute;
   top: 0;
   left: 0;
   max-width: none;
   max-height: none;
   width: 100%;
   height: 100%;
   margin: 0 !important;
   padding: 0 !important;
   border: none !important;
 }

 .guillotine-sample {
   position: absolute !important;
   top: -100000px !important;
   left: -100000px !important;
   width: auto !important;
   height: auto !important;
 }

.mentions {
  margin: 20px 0;
}

.mentions--singleLine .mentions__control {
  display: inline-block;
}
.mentions--singleLine .mentions__higlighter {
  padding: 1px;
  border: 2px inset transparent;
}
.mentions--singleLine .mentions__input {
  padding: 5px;
  border: 2px inset;
}

.mentions--multiLine .mentions__control {
  font-family: 'Source Sans Pro', sans-serif;;
  font-size: 16px;
  border: 1px solid $pubveryLightPinkTwo;
  height: 200px;
}
.mentions--multiLine .mentions__highlighter {
  padding: 10px;
}
.mentions--multiLine .mentions__input {
  padding: 10px;
  min-height: 200px;
  outline: 0;
  border: 0;
}
.quick-share-twitter {
  .mentions {
    margin: 0;
  }
  .mentions--multiLine .mentions__input {
    min-height: 100px;
    padding: 15px;
  }
  .mentions--multiLine .mentions__control{
    height: 100px;
    border-radius: 5px;
    background-color: #f8f8f8;
  }
}
.mentions__suggestions__list {
  background-color: $pubWhite;
  border: 1px solid $pubveryLightPinkTwo;
  font-size: 10px;
}

.mentions__suggestions__item {
  padding: 5px 15px;
  border-bottom: 1px solid $pubveryLightPinkTwo;
}

.mentions__suggestions__item--focused {
  background-color: $pubdeepSkyBlue;
  color: $pubWhite;
}

.mentions__mention {
  background-color: $pubdeepSkyBlue;
  color: $pubWhite;
}
 .image {
   text-align: center;
 }
 #figure-image {
   width: 80%;
 }

 .image figcaption {
   text-align: center;
   padding: 4px;
   width: 80%;
   margin-left: 10%;
   border-bottom: 1px solid grey;
 }

.quick-share-draftjs .RichEditor-styleButton[title*="Blockquote"]{
  display: none;
}

.quick-share-draftjs .RichEditor-styleButton[title*="Embed"]{
  display: none;
}

.quick-share-draftjs > div.RichEditor-root{
  margin-bottom: 0;
}

@media only screen and (max-width: 600px) {
  #intercom-container > div > iframe {
    bottom: 12px;
    display: none;
  }
}
.MuiButton-containedPrimary:hover {
  background-color:#585858 !important;
  color:#FFFFFF;
}
.MuiButton-containedSecondary:hover {
  background-color:#e5e5e5 !important;
  color:#101010,
}

#scrollbarhide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
#scrollbarhide {
  -ms-overflow-style: none;
}

//TODO: Overriding getstream component for now, update with the way component is structured.

.str-chat-channel {
  height: 100%;
}

.str-chat__message-livestream-actions {
  display: none !important;
}

.str-chat__message-attachment {
  pointer-events: none;
}

.str-chat__input-footer {
  display: none;
}

.str-chat__header-livestream {
  background: #1b1b1b !important;
  color: white;
}
.str-chat__input {
  background: #1b1b1b !important;
}
.str-chat__textarea__textarea {
  background: #101010 !important;
  color: white; 
}

.str-chat__ul {
  color: white;
}

.rfu-thumbnail-placeholder {
  display: none;
}
.str-chat__list{
  background: #101010;
}
