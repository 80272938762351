$pubBlack: #000000;
$pubdeepSkyBlue: #101010;
$pubGreyishBrown: #464646;
$pubVeryLightPink: #f0f0f0;
$pubPaleGrey: #f5faff;
$pubGreenBlue: #00a984;
$pubdeepSkyBlueTwo: #1877f2;
$pubPaleGreyTwo: #ebf5ff;
$pubScarlet: #b00020;
$pubRightOrange: #ff6c00;
$pubLightBlue: #97c9ff;
$pubRed: #d10000;
$pubWhite: #ffffff;
$pubbrownGrey: #8e8e8e;
$pubveryLightPinkTwo: #d8d8d8;
$pubGreywhite: #f8f8f8;